import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface OverflowMenuOptions {
  id: string;
  label: string;
  field?: string;
  condition?: any;
  subfield?: string;
  subcondition?: any;
  hide?: any;
}

@Component({
  selector: 'lib-overflow-menu',
  templateUrl: './overflow-menu.component.html',
  styleUrls: ['./overflow-menu.component.scss']
})
export class OverflowMenuComponent {
  @Input() options: OverflowMenuOptions[];
  @Input() data: any;
  @Output() optionClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  getSortedOptions() {
    return this.options?.sort((a, b) => a.label.localeCompare(b.label))
  }

  trackById(index: number, item: any) {
    return item.id
  }
}
