/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientDetailsViewModel } from './patientDetailsViewModel';

export interface ImaginePayMessageViewModel {
  id?: string;
  patientId?: string | null;
  accountNumber?: number;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  messageType?: string | null;
  createdDate?: string;
  serviceDate?: string | null;
  onlineAccountNumber?: string | null;
  isCleared?: boolean;
  readonly showResolve?: boolean;
  readonly showAssign?: boolean;
  paymentPlanMessageId?: string | null;
  numberOfPayments?: number;
  amount?: number | null;
  scheduledPlanId?: string | null;
  initialPaymentAmount?: number | null;
  initialPaymentReferenceNumber?: string | null;
  initialPaymentTransactionId?: string | null;
  scheduledAmount?: number | null;
  reason?: string | null;
  recurringPaymentAmount?: number | null;
  nextPaymentAmount?: number | null;
  nextPaymentDate?: string | null;
  dateCancelled?: string | null;
  dateCompleted?: string | null;
  insuranceMessageId?: string | null;
  insuranceType?: string | null;
  insuranceCarrier?: string | null;
  insurancePlan?: string | null;
  policyNumber?: string | null;
  groupNumber?: string | null;
  insuredName?: string | null;
  insuredDob?: string | null;
  readonly insuredDobDisplay?: string | null;
  insuredGender?: string | null;
  relationToPatient?: string | null;
  insuredAddress1?: string | null;
  insuredAddress2?: string | null;
  insuredCity?: string | null;
  insuredStateCode?: string | null;
  insuredZip?: string | null;
  addressMessageId?: string | null;
  addressType?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  email?: string | null;
  patient?: PatientDetailsViewModel;
}
