/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImaginePayMessageGuarantorUpdateModel } from './imaginePayMessageGuarantorUpdateModel';
import { ImaginePayMessagePatientUpdateModel } from './imaginePayMessagePatientUpdateModel';
import { ImaginePayMessageInsuranceUpdateModel } from './imaginePayMessageInsuranceUpdateModel';

export interface ImaginePayMessageUpdateModel {
  patient?: ImaginePayMessagePatientUpdateModel;
  guarantor?: ImaginePayMessageGuarantorUpdateModel;
  insurances?: Array<ImaginePayMessageInsuranceUpdateModel> | null;
}
