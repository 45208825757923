/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ImaginePayMessagePatientUpdateModel {
  id?: string;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  suffix?: string | null;
  birthDate?: string;
  sex?: string | null;
  ssn?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  homePhone?: string | null;
  email?: string | null;
  primaryInsuranceId?: string | null;
  secondaryInsuranceId?: string | null;
  tertiaryInsuranceId?: string | null;
}
